import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { ErrorLayout } from "~/_components/Fallback/ErrorLayout";
import { Icon } from "~/modules/Common/Icons";

function InternalServerError() {
  const { t } = useTranslation([ENameSpace.Common]);

  const toMainPage = () => {
    const searchParams = new URLSearchParams(location.search);
    const subdomainFromPathname = location.pathname.startsWith("/@")
      ? location.pathname.split("/")[1]
      : "";

    const subdomain = searchParams.get("subdomain") ?? subdomainFromPathname;

    if (!subdomain) {
      location.replace(`/`);
      return;
    }

    const matched = /^@/g.test(subdomain);
    location.replace(`/${matched ? subdomain : `@${subdomain}`}`);
  };

  return (
    <ErrorLayout
      renderIcon={
        <Icon className="12.625rem 7.75rem mb-5" iconType="Error-500" />
      }
      renderSubText={
        <>
          <span>{t(`${ENameSpace.Common}:Error.500.SubText1`)}</span>
          <span>{t(`${ENameSpace.Common}:Error.500.SubText2`)}</span>
        </>
      }
      title={t(`${ENameSpace.Common}:Error.500.MainText`)}
    >
      <button
        type="button"
        className="typo-m1 mt-4 flex h-10 w-[9.625rem] items-center justify-center gap-x-1 rounded-full bg-ciety-wh text-ciety-dk"
        onClick={toMainPage}
      >
        {t(`${ENameSpace.Common}:Error.500.ButtonText`)}
      </button>
    </ErrorLayout>
  );
}

export { InternalServerError };
