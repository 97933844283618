import { Icon } from "~/modules/Common/Icons";
import { DownloadButton } from "./DownloadButton";
import { AppIconButton } from "../Buttons/AppIconButton";

import PcLandingMotion from "~/_public/images/browserNotSupported/PC_Landing_Motion.mp4";
import { CietyScrollBar } from "~/modules/Common/Layout";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";

const hrefs = {
  chrome: "https://www.google.com/chrome/",
  googlePlay:
    "https://play.google.com/store/apps/details?id=com.ciety.xyz.prod&pli=1",
  appStore:
    "https://apps.apple.com/us/app/ciety-web3-game-community/id6443814305",
};

export function DesktopBrowserNotSupported() {
  const { t } = useTranslation([ENameSpace.Common]);

  return (
    <CietyScrollBar className="flex-center relative h-screen bg-ciety-lg">
      <AppIconButton className="fixed left-5 top-5 text-ciety-bk" />

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        src={PcLandingMotion}
        autoPlay
        muted
        loop
        playsInline
        className="absolute bottom-0 left-0 right-0"
      />

      <main className="absolute flex flex-col items-center">
        <header className="flex flex-col items-center gap-y-2">
          <h3 className="typo-medium-16">Your World, Your CIETY</h3>
          <Icon iconType="CIETY-Logo" className="h-10 w-[12.8125rem]" />
        </header>

        <section className="mt-10 flex flex-col items-center gap-y-[0.62rem]">
          <span className="typo-headline-m-24">
            {t(
              `${ENameSpace.Common}:NotSupported.Desktop.PleaseAccessUsingChromeBrowser`,
            )}
          </span>
          <p className="typo-r1 whitespace-pre text-center">
            {t(
              `${ENameSpace.Common}:NotSupported.Desktop.ThisPageIsNotSupportedByYourBrowser`,
            )}
          </p>
        </section>

        <section className="mt-[3.75rem] flex flex-col gap-y-3">
          <DownloadButton href={hrefs.chrome}>
            <Icon iconType="logo-Chrome" className="h-6 w-6" />
            {t(
              `${ENameSpace.Common}:NotSupported.Desktop.DownloadChromeBrowser`,
            )}
          </DownloadButton>
          <DownloadButton href={hrefs.googlePlay}>
            <Icon iconType="logo-Googleplay" className="h-6 w-6" />
            {t(`${ENameSpace.Common}:NotSupported.Desktop.DownloadGooglePlay`)}
          </DownloadButton>
          <DownloadButton href={hrefs.appStore}>
            <Icon iconType="SNS-Apple" className="h-6 w-6" />
            {t(`${ENameSpace.Common}:NotSupported.Desktop.DownloadAppStore`)}
          </DownloadButton>
        </section>
      </main>
    </CietyScrollBar>
  );
}
