import { FormEvent, useEffect, useState } from "react";
import { useGoogleConnect } from "../hooks/useGoogleConnect";
import { assert } from "~/_utils/assert";
import { TAuthConnectRequestsConsumeSuccessData } from "~/_types/TAuthRequestsConsumeSuccessData";
import { postCommonGoogleAccounts } from "~/_api/youtube/postCommonGoogleAccounts";
import { CietyScrollBar } from "~/modules/Common/Layout";
import { RadioGroup } from "@headlessui/react";
import { cn } from "~/_utils/converter/cn";
import { CietyButton } from "~/_components/Buttons/CietyButton";
import { isNil } from "@fxts/core";
import { Icon } from "~/modules/Common/Icons";
import { cietyAlert } from "~/_utils/overlay/cietyAlert";
import { TGoogleAccount } from "~/_types/TGoogleAccount";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { NavigationCancelCloseButton } from "~/_components/Buttons/NavigationCancelCloseButton";
import { GoogleAccountPolicy } from "~/_components/OAuth/components/GoogleAccountPolicy";
import { useErrorBoundary } from "react-error-boundary";

const OVERFLOW_BOUNDARY_COUNT = 4;

type TProps = {
  onClose?: () => void;
  onSubmit: (selectedGoogleAccount: TGoogleAccount) => void;
  displayName: string;
};

export function GoogleAccountListToJoinModal({
  onClose,
  onSubmit,
  displayName,
}: TProps) {
  const [googleAccounts, setGoogleAccounts] = useState<TGoogleAccount[]>([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedGoogleAccount, setSelectedGoogleAccount] =
    useState<TGoogleAccount | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const { t } = useTranslation([ENameSpace.Common]);
  const { showBoundary } = useErrorBoundary();

  useEffect(
    function initGoogleAccounts() {
      (async () => {
        try {
          const googleAccountsResponse = await postCommonGoogleAccounts({});
          const googleAccounts = googleAccountsResponse.data;

          setGoogleAccounts(googleAccounts);
          if (googleAccounts.length === 1) {
            setSelectedGoogleAccount(googleAccounts[0]);
          }

          setIsSuccess(true);
        } catch (err) {
          showBoundary(err);
        }
      })();
    },
    [showBoundary],
  );

  const handleConsumeSuccess = async (
    consumeData: TAuthConnectRequestsConsumeSuccessData,
  ) => {
    assert(consumeData.accountType === "Google");

    try {
      setIsUpdating(true);

      const googleAccountsResponse = await postCommonGoogleAccounts({});
      setGoogleAccounts(googleAccountsResponse.data);

      const justConsumedGoogleAccount = googleAccountsResponse.data.find(
        (googleAccount) => googleAccount.id === consumeData.account.id,
      );
      if (!justConsumedGoogleAccount) {
        // 로그인한 구글 계정에 유튜브 채널이 없을 시
        return await cietyAlert({
          body: t(
            `${ENameSpace.Common}:OAuth.GoogleAccountListToJoin.YouCannotLinkMultipleGoogleAccounts`,
          ),
          buttonText: t(
            `${ENameSpace.Common}:OAuth.GoogleAccountListToJoin.Check`,
          ),
        });
      }

      // 로그인한 구글 계정에 유튜브 채널이 있을 시
      setSelectedGoogleAccount(justConsumedGoogleAccount);
    } catch (err) {
      await cietyAlert({
        body: t(`${ENameSpace.Common}:Alert.TryAgainLater`),
      });
    } finally {
      setIsUpdating(false);
    }
  };
  const { handleGoogleConnect, isConsuming } =
    useGoogleConnect(handleConsumeSuccess);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    assert(selectedGoogleAccount);
    event.preventDefault();

    onSubmit(selectedGoogleAccount);
  };

  // 기획에 따라 최근 추가된 계정이 상단에 위치합니다.
  const sortedGoogleAccounts = [...googleAccounts].reverse();
  const hasGoogleAccount = sortedGoogleAccounts.length;
  const isRunning = isConsuming || isUpdating;

  if (!isSuccess) {
    return null;
  }

  return (
    <CietyScrollBar className="fixed inset-0 flex w-full flex-col items-center justify-center bg-ciety-dim-90 backdrop-blur-[1.25rem]">
      <NavigationCancelCloseButton
        onClick={() => onClose?.()}
        className="fixed right-5 top-5"
      />

      {hasGoogleAccount ? (
        <>
          <header className="typo-medium-28 whitespace-pre-wrap text-center text-ciety-wh">
            {t(
              `${ENameSpace.Common}:OAuth.GoogleAccountListToJoin.ToJoinTheCommunityNamePleaseSelectTheAccountYouWouldLikeToBeActiveOn`,
              {
                displayName,
              },
            )}
          </header>

          <section className="flex w-[25rem] flex-col">
            <form
              className="mt-10 flex w-full flex-col gap-y-5"
              onSubmit={handleSubmit}
            >
              <div className="relative">
                <RadioGroup
                  value={selectedGoogleAccount}
                  onChange={setSelectedGoogleAccount}
                  className="flex max-h-[18.25rem] flex-col gap-y-3 overflow-auto scrollbar-hide"
                >
                  {sortedGoogleAccounts.map((account) => {
                    const showAddress = account.isLoginAccount;

                    return (
                      <RadioGroup.Option key={account.id} value={account}>
                        {({ checked }) => (
                          <div
                            className={cn(
                              "flex h-16 w-full cursor-pointer items-center gap-x-2 rounded-2xl border px-5 text-ciety-wh",
                              checked
                                ? "border-ciety-lg"
                                : "border-ciety-dk-60 hover:border-ciety-gy",
                            )}
                          >
                            <img
                              src={account.channelImageUrl}
                              alt="Channel"
                              className="h-10 w-10 rounded-full object-cover"
                            />
                            <span
                              className={cn("typo-m1 truncate", {
                                "max-w-[9.5rem]": showAddress,
                              })}
                            >
                              {account.channelName}
                            </span>
                            {showAddress && (
                              <span className="typo-r2 max-w-[9.5rem] truncate text-ciety-dk-60">
                                {account.address}
                              </span>
                            )}
                          </div>
                        )}
                      </RadioGroup.Option>
                    );
                  })}
                </RadioGroup>
                {sortedGoogleAccounts.length >= OVERFLOW_BOUNDARY_COUNT && (
                  <div
                    className="absolute bottom-0 left-0 right-0 h-[3.75rem]"
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(19, 19, 26, 0.00) 0%, #13131A 100%)",
                    }}
                  />
                )}
              </div>

              <CietyButton
                form={{
                  variant: "LgFill",
                  theme: "lg",
                }}
                borderRadius={12}
                disabled={isNil(selectedGoogleAccount)}
              >
                {t(
                  `${ENameSpace.Common}:OAuth.GoogleAccountListToJoin.JoinTheCommunity`,
                )}
              </CietyButton>
            </form>

            <CietyButton
              form={{
                variant: "LgFill",
                theme: "wh",
              }}
              borderRadius={12}
              className="mt-[3.75rem] gap-x-2"
              onClick={() => handleGoogleConnect()}
              isLoading={isRunning}
              disabled={isRunning}
            >
              {!isRunning && (
                <>
                  <Icon iconType="google" />
                  <span>
                    {t(
                      `${ENameSpace.Common}:OAuth.GoogleAccountListToJoin.LinkAdditionalGoogleAccounts`,
                    )}
                  </span>
                </>
              )}
            </CietyButton>
          </section>
        </>
      ) : (
        <>
          <header className="typo-medium-28 whitespace-pre-wrap text-center text-ciety-wh">
            {t(
              `${ENameSpace.Common}:OAuth.GoogleAccountListToJoin.YouNeedToGrantGoogleAccountPermissionToSignUp`,
              {
                displayName,
              },
            )}
          </header>

          <section className="mt-10 flex w-[25rem] flex-col gap-y-3">
            <CietyButton
              form={{
                variant: "LgFill",
                theme: "wh",
              }}
              borderRadius={12}
              className="gap-x-2"
              onClick={() => handleGoogleConnect()}
              isLoading={isRunning}
              disabled={isRunning}
            >
              {!isRunning && (
                <>
                  <Icon iconType="google" />
                  <span>
                    {t(
                      `${ENameSpace.Common}:OAuth.GoogleAccountListToJoin.SignUpWithYourGoogleAccount`,
                    )}
                  </span>
                </>
              )}
            </CietyButton>
            <GoogleAccountPolicy />
            <div className="flex items-center gap-x-[0.38rem] text-ciety-gy">
              <Icon iconType="Alert Sign Circle" className="h-4 w-4" />
              <span className="typo-r2">
                {t(
                  `${ENameSpace.Common}:OAuth.GoogleAccountListToJoin.PleaseAllowFullAccessToYourGoogleAccountToJoinTheCommunity`,
                )}
              </span>
            </div>
          </section>
        </>
      )}
    </CietyScrollBar>
  );
}
