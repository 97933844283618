import { CietyButton } from "~/_components/Buttons/CietyButton";
import { downloadApp } from "~/_utils/mobile/downloadApp";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { Icon } from "~/modules/Common/Icons";

export function MobileBrowserNotSupported() {
  const { t } = useTranslation([ENameSpace.Common]);

  return (
    <div className="absolute inset-0 overflow-hidden bg-ciety-lg">
      <video
        className="absolute bottom-[-5rem] w-full object-cover"
        autoPlay
        muted
        loop
        playsInline
      >
        <source
          src="https://static.ciety.xyz/public/community/unsupported-video.mp4"
          type="video/mp4"
        />
      </video>
      <div className="absolute flex w-full flex-col gap-y-8 px-10 pt-[6.75rem] text-center">
        <section className="text-ciety-bk">
          <h3 className="typo-medium-16 mb-[0.5rem]">Your World, Your CIETY</h3>
          <Icon iconType="CIETY-Logo" className="h-10 w-full" />
        </section>
        <section className="text-center ">
          <h1 className="typo-headline-m-20">
            {t(`${ENameSpace.Common}:NotSupported.Mobile.Title`)}
          </h1>
          <p className="typo-r2 whitespace-pre-wrap">
            {t(`${ENameSpace.Common}:NotSupported.Mobile.Text`)}
          </p>
        </section>

        <CietyButton
          form={{
            variant: "LgFill",
            theme: "bk",
          }}
          borderRadius={12}
          onClick={() => downloadApp()}
        >
          {t(`${ENameSpace.Common}:NotSupported.Mobile.Button`)}
        </CietyButton>
      </div>
    </div>
  );
}
