export enum Level {
  Lv10 = "10",
  Lv20 = "20",
  Lv30 = "30",
  Lv40 = "40",
  Lv100 = "100",
}

const RAW_Z_LEVELS = {
  GLOBAL: {
    HEADLESS_UI_PORTAL_40: Level.Lv40 /** global.css */,
    MOBILE_HEADER_40: Level.Lv40,
    PC_EMOJI_PICKER_20: Level.Lv20,
    SELECT_OPTIONS_20: Level.Lv20,
    MOBILE_EMOJI_PICKER_10: Level.Lv10,
    UTIL_MENU_10: Level.Lv10,
    FIXED_VIDEO_10: Level.Lv10,
    SLIDER_ARROW_10: Level.Lv10,
    MOBILE_MODAL_WITH_DRAG_GESTURE: Level.Lv100,
  },
  FEED: {
    MENTION_PANEL_40: Level.Lv40,
    PC_EMOJI_PICKER_PANEL_20: Level.Lv20,
    MEMBER_LIST_10: Level.Lv10,
    MEMBER_LIST_BUTTON_20: Level.Lv20,
    COMMENT_EDITOR_CONTENT_EDITABLE_10: Level.Lv10,
    MOBILE_ADD_POST_BUTTON_10: Level.Lv10,
    PC_UPDATE_FEED_BUTTON_10: Level.Lv10,
    MOBILE_UPDATE_FEED_BUTTON_20: Level.Lv20,
    UPDATE_NOTIFICATION_BUTTON_10: Level.Lv10,
    HEART_10: Level.Lv10,
    RELAY_10: Level.Lv10,
    LEFTOVER_IMG_10: Level.Lv10,
  },
  POST: {
    EDITED_AT_TOOLTIP_10: Level.Lv10,
  },
  PORTAL: {
    IMAGE_DETAIL_CLOSE_BUTTON_10: Level.Lv10,
    SETTING_CLOSE_BUTTON_10: Level.Lv10,
  },
  MOBILE_DETAIL: {
    CLOSE_BUTTON_10: Level.Lv10,
  },
  SEARCH: {
    FILTER_PICKER_SIDE_BUTTON_30: Level.Lv30,
    FILTER_PICKER_20: Level.Lv20,
    MOBILE_GO_TO_TOP_BUTTON_10: Level.Lv10,
    FILTER_PICKER_OVERLAY_10: Level.Lv10,
  },
  DM: {
    CONFIRM_MODAL: Level.Lv30,
    MODAL: Level.Lv20,
    MODAL_DRAWER: Level.Lv10,
    ADD_MODAL: Level.Lv20,
    MODAL_HEADER: Level.Lv10,
    MOBILE_CONFIRM_EXIST: Level.Lv10,
    MOBILE_CONFIRM_EXIST_BACKGROUND: Level.Lv100,
  },
  EDITOR: {
    ROOT: Level.Lv30,
    MENTION_TOOLTIP: Level.Lv30,
    LINK_TOOLTIP: Level.Lv30,
    AUTO_EMBED_TOOLTIP: Level.Lv30,
    TEXT_DRAG_BUBBLE: Level.Lv30,
  },
};

type RAW_TYPE = typeof RAW_Z_LEVELS;

export const Z_LEVELS: {
  [LAYER in keyof RAW_TYPE]: {
    [NAME in keyof RAW_TYPE[LAYER]]: Level;
  };
} = RAW_Z_LEVELS;
