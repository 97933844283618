"use client";

import { escapeHtml } from "~/_utils/converter/escapeHtml";
import { renderToString } from "~/_utils/converter/renderToString";
import { ReactNode } from "react";

type TAlertProps = {
  body: ReactNode;
  buttonText?: string;
  buttonColor?: "green" | "red" | "white" | "gray";
};

export async function cietyAlert({
  buttonColor = "green",
  buttonText = "확인",
  body,
}: TAlertProps): Promise<void> {
  const _body =
    typeof body == "string" ? escapeHtml(body) : await renderToString(body);

  return new Promise((resolve) => {
    const html = `
      <div id="alert-modal-container" class="modal-container">
        <div id="alert-modal" class="modal-wrapper">
          <div id="alert-modal-content" class="modal-content">${_body}</div>
          <div class="modal-button-wrapper">
            <button
              id="alert-modal-button"
              class="modal-button text-${buttonColor}"
              type="button"
            >${escapeHtml(buttonText)}
            </button>
          </div>
        </div>
      </div>
    `;

    const wrapper = document.createElement("div");
    wrapper.innerHTML = html;
    const alertContainerNode = wrapper.children[0];

    const closeModal = () => {
      document.querySelector("html")?.classList.remove("overflow-hidden");
      document.removeEventListener("keydown", handleKeyDown);
      document.body.removeChild(alertContainerNode);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" || event.key === "Escape") {
        event.preventDefault();
        closeModal();
        resolve();
      }
    };

    wrapper
      .querySelector("#alert-modal-button")
      ?.addEventListener("click", () => {
        closeModal();
        resolve();
      });
    document.addEventListener("keydown", handleKeyDown);
    document.querySelector("html")?.classList.add("overflow-hidden");
    document.body.appendChild(alertContainerNode);
  });
}
