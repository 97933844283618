import { ExploreSectionContainer } from "~/_app/[dao]/explore/components/mobile/ExploreSectionContainer";
import { Banner } from "~/_app/[dao]/home/components/mobile/Banner/Banner";
import { Live } from "~/_app/[dao]/home/components/mobile/Live/Live";
import { Marketplace } from "~/_app/[dao]/home/components/mobile/Marketplace/Marketplace";
import { MerchStore } from "~/_app/[dao]/home/components/mobile/MerchStore/MerchStore";
import { Sns } from "~/_app/[dao]/home/components/mobile/Sns/Sns";
import { AllPosts } from "~/_app/[dao]/home/components/mobile/AllPosts/AllPosts";
import { HotPosts } from "~/_app/[dao]/home/components/mobile/HotPosts/HotPosts";
import { Profile } from "~/_app/[dao]/home/components/mobile/Profile/Profile";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { CietyButton } from "~/_components/Buttons/CietyButton";
import { downloadApp } from "~/_utils/mobile/downloadApp";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { assertIsDefined } from "~/_utils/assert";

export function ExploreSection() {
  const { t } = useTranslation([ENameSpace.Explore]);
  const { daoHome } = useDaoHomeStore();
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);

  return (
    <div className="mx-auto h-screen w-full">
      {daoHome.sections.map((section) => {
        if (section.type === EDaoHomeSection.HotPosts) {
          return (
            <ExploreSectionContainer
              key={section.id}
              sectionType={section.type}
            >
              <HotPosts sectionId={section.id} />
            </ExploreSectionContainer>
          );
        } else if (section.type === EDaoHomeSection.AllPosts) {
          return (
            <ExploreSectionContainer
              key={section.id}
              sectionType={section.type}
            >
              <AllPosts sectionId={section.id} />
            </ExploreSectionContainer>
          );
        } else if (section.type === EDaoHomeSection.Profile) {
          return (
            <div key={section.id}>
              <Profile
                sectionId={section.id}
                notch={null}
                renderSticky={<></>}
              />
              <div className="px-5">
                <CietyButton
                  onClick={() => downloadApp(daoSubdomain)}
                  className="w-full"
                  form={{
                    variant: "LgFill",
                    theme: "lg",
                  }}
                >
                  {t(`${ENameSpace.Explore}:Root.AppDownload`)}
                </CietyButton>
              </div>
            </div>
          );
        } else if (section.type === EDaoHomeSection.Banner) {
          return (
            <ExploreSectionContainer
              key={section.id}
              sectionType={section.type}
            >
              <Banner sectionId={section.id} />
            </ExploreSectionContainer>
          );
        } else if (section.type === EDaoHomeSection.LiveStreaming) {
          return (
            <ExploreSectionContainer
              key={section.id}
              sectionType={section.type}
            >
              <Live sectionId={section.id} />
            </ExploreSectionContainer>
          );
        } else if (section.type === EDaoHomeSection.Sns) {
          return (
            <ExploreSectionContainer
              key={section.id}
              sectionType={section.type}
            >
              <Sns sectionId={section.id} />
            </ExploreSectionContainer>
          );
        } else if (section.type === EDaoHomeSection.Marketplace) {
          return (
            <ExploreSectionContainer
              key={section.id}
              sectionType={section.type}
            >
              <Marketplace sectionId={section.id} />
            </ExploreSectionContainer>
          );
        } else if (section.type === EDaoHomeSection.MerchStore) {
          return (
            <ExploreSectionContainer
              key={section.id}
              sectionType={section.type}
            >
              <MerchStore sectionId={section.id} />
            </ExploreSectionContainer>
          );
        } else {
          throw new Error("Dose not exist section type");
        }
      })}
    </div>
  );
}
