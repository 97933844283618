import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import { Member } from "~/modules/BlockChain/Inventory/types";
import { spaceMenuIndexByTitle } from "~/modules/Space/constants";
import { SpaceMenu } from "~/modules/Space/types";
import { spaceSlice } from "~/store/space/slice";
import { SpaceState } from "~/store/space/types";

export const windowController = {
  open: ((state, { payload: member }) => {
    state.isOpen = true;
    state.selectedMember = member;
  }) as SpaceReducer<Member | string | null>,
  close: ((state) => {
    state.isOpen = false;
    state.selectedMember = null;
  }) as SpaceReducer,
};

export const menuController = {
  // 스페이스의 메뉴 선택
  select: ((state, { payload: selectedMenu }) => {
    state.selectedMenu = selectedMenu;
    if (selectedMenu !== "Digital collectibles") {
      state.currentViewPage["Digital collectibles"] =
        spaceSlice.getInitialState().currentViewPage["Digital collectibles"];
      state.navigationPath["Digital collectibles"] =
        spaceSlice.getInitialState().navigationPath["Digital collectibles"];
    }
  }) as SpaceReducer<SpaceMenu["title"]>,
};

export const pathController = {
  // 모든 메뉴의 경로 루트로 초기화
  resetMenuPathPageAll: ((state) => {
    state.navigationPath = spaceSlice.getInitialState().navigationPath;
    state.currentViewPage = spaceSlice.getInitialState().currentViewPage;
  }) as SpaceReducer,

  // 선택되어 있는 메뉴의 경로 한 단계 추가
  pushPath: ((state, { payload: pathName }) => {
    state.navigationPath[state.selectedMenu].push(pathName);
  }) as SpaceReducer<string>,

  // 선택되어 있는 메뉴의 마지막 경로 제거
  popPath: ((state) => {
    state.navigationPath[state.selectedMenu].pop();
  }) as SpaceReducer,
};

export const pageController = {
  // 선택된 메뉴에 할당되어 있는 viewPage 리스트의 깊이 전환을 제공하는 API
  // Todo: 리팩토링
  switchPage: ((state, { payload }) => {
    const selectedMenu = state.selectedMenu;
    switch (selectedMenu) {
      case "Profile": {
        const pageList = spaceMenuIndexByTitle[selectedMenu]["viewPages"];
        const currentPage = state.currentViewPage[selectedMenu];
        const currentIdx = pageList.indexOf(currentPage);
        state.currentViewPage = {
          ...state.currentViewPage,
          Profile:
            pageList[getSwitchIndex(currentIdx, pageList.length, payload)],
        };
        break;
      }
      case "Digital collectibles": {
        const pageList = spaceMenuIndexByTitle[selectedMenu]["viewPages"];
        const currentPage = state.currentViewPage[selectedMenu];
        const currentIdx = pageList.indexOf(currentPage);
        const idxGoto = getSwitchIndex(currentIdx, pageList.length, payload);
        const pageGoto = pageList[idxGoto];
        state.currentViewPage = {
          ...state.currentViewPage,
          "Digital collectibles": pageGoto,
        };
        break;
      }
      case "Cryptocurrency": {
        const pageList = spaceMenuIndexByTitle[selectedMenu]["viewPages"];
        const currentPage = state.currentViewPage[selectedMenu];
        const currentIdx = pageList.indexOf(currentPage);
        state.currentViewPage = {
          ...state.currentViewPage,
          Cryptocurrency:
            pageList[getSwitchIndex(currentIdx, pageList.length, payload)],
        };
        break;
      }
      case "Creator earning": {
        const pageList = spaceMenuIndexByTitle[selectedMenu]["viewPages"];
        const currentPage = state.currentViewPage[selectedMenu];
        const currentIdx = pageList.indexOf(currentPage);
        state.currentViewPage = {
          ...state.currentViewPage,
          "Creator earning":
            pageList[getSwitchIndex(currentIdx, pageList.length, payload)],
        };
        break;
      }
      case "My Account": {
        const pageList = spaceMenuIndexByTitle[selectedMenu]["viewPages"];
        const currentPage = state.currentViewPage[selectedMenu];
        const currentIdx = pageList.indexOf(currentPage);
        state.currentViewPage = {
          ...state.currentViewPage,
          "My Account":
            pageList[getSwitchIndex(currentIdx, pageList.length, payload)],
        };
        break;
      }
      case "My Payments": {
        const pageList = spaceMenuIndexByTitle[selectedMenu]["viewPages"];
        const currentPage = state.currentViewPage[selectedMenu];
        const currentIdx = pageList.indexOf(currentPage);
        state.currentViewPage = {
          ...state.currentViewPage,
          "My Payments":
            pageList[getSwitchIndex(currentIdx, pageList.length, payload)],
        };
        break;
      }
    }
  }) as SpaceReducer<"prev" | "next">,
};

type SpaceReducer<PayloadType = void> = CaseReducer<
  SpaceState,
  PayloadAction<PayloadType>
>;

const getSwitchIndex = (
  currentIdx: number,
  pageListLen: number,
  direction: "prev" | "next",
) => {
  switch (direction) {
    case "prev": {
      return currentIdx === 0 ? currentIdx : currentIdx - 1;
    }
    case "next": {
      return currentIdx === pageListLen - 1 ? currentIdx : currentIdx + 1;
    }
  }
};
