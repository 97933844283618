import { IconType } from "./types";
// 수동 작업 X, npm run update:assets:Icon 명령어 사용해주세요
export const loader: Map<IconType, () => Promise<typeof import("*.svg")>> =
  new Map()
    .set("Add Channel-Line", () => import("./assets/Add Channel-Line.svg"))
    .set("Add Channel", () => import("./assets/Add Channel.svg"))
    .set("Add Folder-Line", () => import("./assets/Add Folder-Line.svg"))
    .set("Add Folder", () => import("./assets/Add Folder.svg"))
    .set("Add Person-Line", () => import("./assets/Add Person-Line.svg"))
    .set("Add Person", () => import("./assets/Add Person.svg"))
    .set("After", () => import("./assets/After.svg"))
    .set(
      "Alert Sign Circle-Line",
      () => import("./assets/Alert Sign Circle-Line.svg"),
    )
    .set("Alert Sign Circle", () => import("./assets/Alert Sign Circle.svg"))
    .set(
      "Alert Sign Triangle-Line",
      () => import("./assets/Alert Sign Triangle-Line.svg"),
    )
    .set(
      "Alert Sign Triangle",
      () => import("./assets/Alert Sign Triangle.svg"),
    )
    .set("Arrow-Down-Line", () => import("./assets/Arrow-Down-Line.svg"))
    .set("Arrow-Down", () => import("./assets/Arrow-Down.svg"))
    .set("Arrow-Up-Line", () => import("./assets/Arrow-Up-Line.svg"))
    .set("Arrow-Up", () => import("./assets/Arrow-Up.svg"))
    .set("Back", () => import("./assets/Back.svg"))
    .set("Badge-Blue", () => import("./assets/Badge-Blue.svg"))
    .set("Badge-LG", () => import("./assets/Badge-LG.svg"))
    .set("Badge-Membership", () => import("./assets/Badge-Membership.svg"))
    .set("Badge-Red", () => import("./assets/Badge-Red.svg"))
    .set("Badge", () => import("./assets/Badge.svg"))
    .set("Block-Line", () => import("./assets/Block-Line.svg"))
    .set("Block", () => import("./assets/Block.svg"))
    .set("Blockchain-Line", () => import("./assets/Blockchain-Line.svg"))
    .set("Blockchain", () => import("./assets/Blockchain.svg"))
    .set("Blog", () => import("./assets/Blog.svg"))
    .set("Bold", () => import("./assets/Bold.svg"))
    .set(
      "Bookmark Disabled-Line",
      () => import("./assets/Bookmark Disabled-Line.svg"),
    )
    .set("Bookmark Disabled", () => import("./assets/Bookmark Disabled.svg"))
    .set("Bookmark-Line", () => import("./assets/Bookmark-Line.svg"))
    .set("Bookmark", () => import("./assets/Bookmark.svg"))
    .set("Bubble-Arrow", () => import("./assets/Bubble-Arrow.svg"))
    .set("Bullet Order", () => import("./assets/Bullet Order.svg"))
    .set("CIETY-Logo", () => import("./assets/CIETY-Logo.svg"))
    .set("Calendar Add-Line", () => import("./assets/Calendar Add-Line.svg"))
    .set("Calendar Add", () => import("./assets/Calendar Add.svg"))
    .set("Calendar-Line", () => import("./assets/Calendar-Line.svg"))
    .set("Calendar", () => import("./assets/Calendar.svg"))
    .set("Camera 1-Line", () => import("./assets/Camera 1-Line.svg"))
    .set("Camera 1", () => import("./assets/Camera 1.svg"))
    .set(
      "Cancel Close Circle-Line",
      () => import("./assets/Cancel Close Circle-Line.svg"),
    )
    .set(
      "Cancel Close Circle",
      () => import("./assets/Cancel Close Circle.svg"),
    )
    .set("Cancel Close", () => import("./assets/Cancel Close.svg"))
    .set("Chains-ETH", () => import("./assets/Chains-ETH.svg"))
    .set("Chains-MATIC", () => import("./assets/Chains-MATIC.svg"))
    .set("Chains-METAMASK", () => import("./assets/Chains-METAMASK.svg"))
    .set("Channel-Line", () => import("./assets/Channel-Line.svg"))
    .set("Channel", () => import("./assets/Channel.svg"))
    .set("Chart", () => import("./assets/Chart.svg"))
    .set("Chat Flip-Line", () => import("./assets/Chat Flip-Line.svg"))
    .set("Chat Flip", () => import("./assets/Chat Flip.svg"))
    .set("Chat-Line", () => import("./assets/Chat-Line.svg"))
    .set("Chat", () => import("./assets/Chat.svg"))
    .set("Chat_2", () => import("./assets/Chat_2.svg"))
    .set(
      "Checkmark Circle-Line",
      () => import("./assets/Checkmark Circle-Line.svg"),
    )
    .set("Checkmark Circle", () => import("./assets/Checkmark Circle.svg"))
    .set(
      "Checkmark Square-Blank-Line",
      () => import("./assets/Checkmark Square-Blank-Line.svg"),
    )
    .set(
      "Checkmark Square-Blank",
      () => import("./assets/Checkmark Square-Blank.svg"),
    )
    .set(
      "Checkmark Square-Line",
      () => import("./assets/Checkmark Square-Line.svg"),
    )
    .set("Checkmark Square", () => import("./assets/Checkmark Square.svg"))
    .set("Checkmark", () => import("./assets/Checkmark.svg"))
    .set("Ciety_Circle", () => import("./assets/Ciety_Circle.svg"))
    .set("Ciety_Symbol", () => import("./assets/Ciety_Symbol.svg"))
    .set("Ciety_Symbol_80", () => import("./assets/Ciety_Symbol_80.svg"))
    .set("Ciety_app version", () => import("./assets/Ciety_app version.svg"))
    .set("Ciety_only symbol", () => import("./assets/Ciety_only symbol.svg"))
    .set("Clock-Line", () => import("./assets/Clock-Line.svg"))
    .set("Clock", () => import("./assets/Clock.svg"))
    .set("Code Block", () => import("./assets/Code Block.svg"))
    .set("Code", () => import("./assets/Code.svg"))
    .set("Color=DP", () => import("./assets/Color=DP.svg"))
    .set("Color=GY", () => import("./assets/Color=GY.svg"))
    .set("Color=LG", () => import("./assets/Color=LG.svg"))
    .set("Color=OG", () => import("./assets/Color=OG.svg"))
    .set("Color=RD", () => import("./assets/Color=RD.svg"))
    .set("Color=SK", () => import("./assets/Color=SK.svg"))
    .set("Color=WH", () => import("./assets/Color=WH.svg"))
    .set("Contract-Line", () => import("./assets/Contract-Line.svg"))
    .set("Contract", () => import("./assets/Contract.svg"))
    .set("Copy", () => import("./assets/Copy.svg"))
    .set(
      "Device-Desktop-Line",
      () => import("./assets/Device-Desktop-Line.svg"),
    )
    .set("Device-Desktop", () => import("./assets/Device-Desktop.svg"))
    .set("Device-Mobile-Line", () => import("./assets/Device-Mobile-Line.svg"))
    .set("Device-Mobile", () => import("./assets/Device-Mobile.svg"))
    .set(
      "Direct Message-Line",
      () => import("./assets/Direct Message-Line.svg"),
    )
    .set("Direct Message", () => import("./assets/Direct Message.svg"))
    .set("Down", () => import("./assets/Down.svg"))
    .set("Download", () => import("./assets/Download.svg"))
    .set("Dropdown_Arrow", () => import("./assets/Dropdown_Arrow.svg"))
    .set("Emoji-Line", () => import("./assets/Emoji-Line.svg"))
    .set("Emoji", () => import("./assets/Emoji.svg"))
    .set("Error-401", () => import("./assets/Error-401.svg"))
    .set("Error-403", () => import("./assets/Error-403.svg"))
    .set("Error-404", () => import("./assets/Error-404.svg"))
    .set("Error-500", () => import("./assets/Error-500.svg"))
    .set("Error-oops", () => import("./assets/Error-oops.svg"))
    .set("Eye Disabled-Line", () => import("./assets/Eye Disabled-Line.svg"))
    .set("Eye Disabled", () => import("./assets/Eye Disabled.svg"))
    .set("Eye-Line", () => import("./assets/Eye-Line.svg"))
    .set("Eye", () => import("./assets/Eye.svg"))
    .set("Facebook", () => import("./assets/Facebook.svg"))
    .set("Filter-Line", () => import("./assets/Filter-Line.svg"))
    .set("Filter", () => import("./assets/Filter.svg"))
    .set("Flag-Line", () => import("./assets/Flag-Line.svg"))
    .set("Flag", () => import("./assets/Flag.svg"))
    .set("Folder-Line", () => import("./assets/Folder-Line.svg"))
    .set("Folder", () => import("./assets/Folder.svg"))
    .set("GIF-Line", () => import("./assets/GIF-Line.svg"))
    .set("GIF", () => import("./assets/GIF.svg"))
    .set("Giftok-Line", () => import("./assets/Giftok-Line.svg"))
    .set("Giftok", () => import("./assets/Giftok.svg"))
    .set("Giftok_2", () => import("./assets/Giftok_2.svg"))
    .set("Governance-Line", () => import("./assets/Governance-Line.svg"))
    .set("Governance", () => import("./assets/Governance.svg"))
    .set("H1-Text", () => import("./assets/H1-Text.svg"))
    .set("H2-Text", () => import("./assets/H2-Text.svg"))
    .set("Heart", () => import("./assets/Heart.svg"))
    .set("Heart_fill", () => import("./assets/Heart_fill.svg"))
    .set("History", () => import("./assets/History.svg"))
    .set("Home-Line", () => import("./assets/Home-Line.svg"))
    .set("Home", () => import("./assets/Home.svg"))
    .set("Horizontal Rule", () => import("./assets/Horizontal Rule.svg"))
    .set("Icon-Animal", () => import("./assets/Icon-Animal.svg"))
    .set("Icon-Car", () => import("./assets/Icon-Car.svg"))
    .set("Icon-Create Merch", () => import("./assets/Icon-Create Merch.svg"))
    .set("Icon-Food", () => import("./assets/Icon-Food.svg"))
    .set("Icon-Food_2", () => import("./assets/Icon-Food_2.svg"))
    .set(
      "Icon-Loading-Number",
      () => import("./assets/Icon-Loading-Number.svg"),
    )
    .set("Icon-Loading", () => import("./assets/Icon-Loading.svg"))
    .set("Icon-Merch", () => import("./assets/Icon-Merch.svg"))
    .set("Icon-Merch_2", () => import("./assets/Icon-Merch_2.svg"))
    .set("Icon-Pause", () => import("./assets/Icon-Pause.svg"))
    .set("Icon-Play", () => import("./assets/Icon-Play.svg"))
    .set("Icon-Product", () => import("./assets/Icon-Product.svg"))
    .set("Icon-Rotate-Screen", () => import("./assets/Icon-Rotate-Screen.svg"))
    .set("Icon-Sports", () => import("./assets/Icon-Sports.svg"))
    .set("Icon-Stop", () => import("./assets/Icon-Stop.svg"))
    .set(
      "Icon-Video-Extention",
      () => import("./assets/Icon-Video-Extention.svg"),
    )
    .set("Icon-Video-Fit", () => import("./assets/Icon-Video-Fit.svg"))
    .set("Icon-Video-Full", () => import("./assets/Icon-Video-Full.svg"))
    .set(
      "Icon-Video-Live-empty",
      () => import("./assets/Icon-Video-Live-empty.svg"),
    )
    .set(
      "Icon-Video-Live-fill",
      () => import("./assets/Icon-Video-Live-fill.svg"),
    )
    .set("Icon-Video-Live", () => import("./assets/Icon-Video-Live.svg"))
    .set("Icon-Video-Mini", () => import("./assets/Icon-Video-Mini.svg"))
    .set("Icon-Video-Next", () => import("./assets/Icon-Video-Next.svg"))
    .set(
      "Icon-Video-Subtitle",
      () => import("./assets/Icon-Video-Subtitle.svg"),
    )
    .set("Icon-Video-Thread", () => import("./assets/Icon-Video-Thread.svg"))
    .set("Icon-Video-VOD", () => import("./assets/Icon-Video-VOD.svg"))
    .set("Icon-Volume-Max", () => import("./assets/Icon-Volume-Max.svg"))
    .set("Icon-Volume-Min", () => import("./assets/Icon-Volume-Min.svg"))
    .set("Icon-Volume-Mute", () => import("./assets/Icon-Volume-Mute.svg"))
    .set("Image-Line", () => import("./assets/Image-Line.svg"))
    .set("Image", () => import("./assets/Image.svg"))
    .set("Indent", () => import("./assets/Indent.svg"))
    .set("Info-Line", () => import("./assets/Info-Line.svg"))
    .set("Info", () => import("./assets/Info.svg"))
    .set("Instagram", () => import("./assets/Instagram.svg"))
    .set("Italic", () => import("./assets/Italic.svg"))
    .set("Key-Line", () => import("./assets/Key-Line.svg"))
    .set("Key", () => import("./assets/Key.svg"))
    .set("Keypad-Line", () => import("./assets/Keypad-Line.svg"))
    .set("Keypad", () => import("./assets/Keypad.svg"))
    .set("Keypad_Close-Line", () => import("./assets/Keypad_Close-Line.svg"))
    .set("Keypad_Close", () => import("./assets/Keypad_Close.svg"))
    .set("Keypad_Open-Line", () => import("./assets/Keypad_Open-Line.svg"))
    .set("Keypad_Open", () => import("./assets/Keypad_Open.svg"))
    .set("Leaderboard", () => import("./assets/Leaderboard.svg"))
    .set("Left", () => import("./assets/Left.svg"))
    .set("Light Bulb-Line", () => import("./assets/Light Bulb-Line.svg"))
    .set("Light Bulb", () => import("./assets/Light Bulb.svg"))
    .set("Line Order", () => import("./assets/Line Order.svg"))
    .set("Link", () => import("./assets/Link.svg"))
    .set("Live Antenna", () => import("./assets/Live Antenna.svg"))
    .set("Lock-Fill", () => import("./assets/Lock-Fill.svg"))
    .set("Lock", () => import("./assets/Lock.svg"))
    .set("Logo-GIFTOK", () => import("./assets/Logo-GIFTOK.svg"))
    .set("Logout", () => import("./assets/Logout.svg"))
    .set("Menu 1-Line", () => import("./assets/Menu 1-Line.svg"))
    .set("Menu 1", () => import("./assets/Menu 1.svg"))
    .set("Merge-View", () => import("./assets/Merge-View.svg"))
    .set("Minus", () => import("./assets/Minus.svg"))
    .set("More Horizontal", () => import("./assets/More Horizontal.svg"))
    .set("More Vertical-Line", () => import("./assets/More Vertical-Line.svg"))
    .set("More Vertical", () => import("./assets/More Vertical.svg"))
    .set("Move list", () => import("./assets/Move list.svg"))
    .set(
      "Mute Notification Bell-Line",
      () => import("./assets/Mute Notification Bell-Line.svg"),
    )
    .set(
      "Mute Notification Bell",
      () => import("./assets/Mute Notification Bell.svg"),
    )
    .set("NFT-Line", () => import("./assets/NFT-Line.svg"))
    .set("NFT", () => import("./assets/NFT.svg"))
    .set("New Window", () => import("./assets/New Window.svg"))
    .set("Normal-Text", () => import("./assets/Normal-Text.svg"))
    .set(
      "Notification Bell-Line",
      () => import("./assets/Notification Bell-Line.svg"),
    )
    .set(
      "Notification Bell-Settings",
      () => import("./assets/Notification Bell-Settings.svg"),
    )
    .set("Notification Bell", () => import("./assets/Notification Bell.svg"))
    .set("Number Order", () => import("./assets/Number Order.svg"))
    .set("Opensea-Line", () => import("./assets/Opensea-Line.svg"))
    .set("Opensea", () => import("./assets/Opensea.svg"))
    .set("Outdent", () => import("./assets/Outdent.svg"))
    .set(
      "PNG-Checkmark Circle-Line_bk",
      () => import("./assets/PNG-Checkmark Circle-Line_bk.svg"),
    )
    .set(
      "PNG-Checkmark Circle-Line_wh",
      () => import("./assets/PNG-Checkmark Circle-Line_wh.svg"),
    )
    .set(
      "PNG-Checkmark Circle_bk",
      () => import("./assets/PNG-Checkmark Circle_bk.svg"),
    )
    .set(
      "PNG-Checkmark Circle_wh",
      () => import("./assets/PNG-Checkmark Circle_wh.svg"),
    )
    .set("Payment_Card", () => import("./assets/Payment_Card.svg"))
    .set("Pin-Line", () => import("./assets/Pin-Line.svg"))
    .set("Pin", () => import("./assets/Pin.svg"))
    .set("Plus", () => import("./assets/Plus.svg"))
    .set("Poll-Line", () => import("./assets/Poll-Line.svg"))
    .set("Poll", () => import("./assets/Poll.svg"))
    .set("Popular-Post-Line", () => import("./assets/Popular-Post-Line.svg"))
    .set("Popular-Post", () => import("./assets/Popular-Post.svg"))
    .set("Post-Line", () => import("./assets/Post-Line.svg"))
    .set("Post", () => import("./assets/Post.svg"))
    .set("PostEditor", () => import("./assets/PostEditor.svg"))
    .set("Prev-Btn", () => import("./assets/Prev-Btn.svg"))
    .set("Prev", () => import("./assets/Prev.svg"))
    .set(
      "Private Channel-Line",
      () => import("./assets/Private Channel-Line.svg"),
    )
    .set("Private Channel", () => import("./assets/Private Channel.svg"))
    .set(
      "Private Chat Flip-Line",
      () => import("./assets/Private Chat Flip-Line.svg"),
    )
    .set("Private Chat Flip", () => import("./assets/Private Chat Flip.svg"))
    .set(
      "Private Video Playe-Line",
      () => import("./assets/Private Video Playe-Line.svg"),
    )
    .set(
      "Private Video Playe",
      () => import("./assets/Private Video Playe.svg"),
    )
    .set("Public-1 2", () => import("./assets/Public-1 2.svg"))
    .set("Public-1", () => import("./assets/Public-1.svg"))
    .set("Public-Line", () => import("./assets/Public-Line.svg"))
    .set("Public", () => import("./assets/Public.svg"))
    .set("Question mark-Line", () => import("./assets/Question mark-Line.svg"))
    .set("Question mark", () => import("./assets/Question mark.svg"))
    .set("Question mark_only", () => import("./assets/Question mark_only.svg"))
    .set(
      "Radio Button-Blank-Line",
      () => import("./assets/Radio Button-Blank-Line.svg"),
    )
    .set("Radio Button", () => import("./assets/Radio Button.svg"))
    .set("Redo", () => import("./assets/Redo.svg"))
    .set("Refresh", () => import("./assets/Refresh.svg"))
    .set("Reply-Line", () => import("./assets/Reply-Line.svg"))
    .set("Reply", () => import("./assets/Reply.svg"))
    .set(
      "Restricted Chat Flip-Line",
      () => import("./assets/Restricted Chat Flip-Line.svg"),
    )
    .set(
      "Restricted Chat Flip",
      () => import("./assets/Restricted Chat Flip.svg"),
    )
    .set(
      "Restricted Video Playe-Line",
      () => import("./assets/Restricted Video Playe-Line.svg"),
    )
    .set(
      "Restricted Video Playe",
      () => import("./assets/Restricted Video Playe.svg"),
    )
    .set("Restricted-Line", () => import("./assets/Restricted-Line.svg"))
    .set("Restricted", () => import("./assets/Restricted.svg"))
    .set("Right", () => import("./assets/Right.svg"))
    .set("Role-Line", () => import("./assets/Role-Line.svg"))
    .set("Role", () => import("./assets/Role.svg"))
    .set("SNS-Apple", () => import("./assets/SNS-Apple.svg"))
    .set("SNS-Blog", () => import("./assets/SNS-Blog.svg"))
    .set("SNS-CHZZK", () => import("./assets/SNS-CHZZK.svg"))
    .set("SNS-Facebook", () => import("./assets/SNS-Facebook.svg"))
    .set("SNS-Google", () => import("./assets/SNS-Google.svg"))
    .set("SNS-Instagram", () => import("./assets/SNS-Instagram.svg"))
    .set("SNS-MarppleShop", () => import("./assets/SNS-MarppleShop.svg"))
    .set("SNS-Opensea-Line", () => import("./assets/SNS-Opensea-Line.svg"))
    .set("SNS-Opensea", () => import("./assets/SNS-Opensea.svg"))
    .set("SNS-Tiktok", () => import("./assets/SNS-Tiktok.svg"))
    .set("SNS-Twitter", () => import("./assets/SNS-Twitter.svg"))
    .set("SNS-X", () => import("./assets/SNS-X.svg"))
    .set("SNS-Youtube", () => import("./assets/SNS-Youtube.svg"))
    .set("SNS-afreecaTV", () => import("./assets/SNS-afreecaTV.svg"))
    .set("SNS_Twitter", () => import("./assets/SNS_Twitter.svg"))
    .set("SNS_Youtube", () => import("./assets/SNS_Youtube.svg"))
    .set("Search-Line", () => import("./assets/Search-Line.svg"))
    .set("Search", () => import("./assets/Search.svg"))
    .set("Settings 1", () => import("./assets/Settings 1.svg"))
    .set("Settings 2", () => import("./assets/Settings 2.svg"))
    .set("Settings-Line", () => import("./assets/Settings-Line.svg"))
    .set("Settings", () => import("./assets/Settings.svg"))
    .set("Share-Line", () => import("./assets/Share-Line.svg"))
    .set("Share", () => import("./assets/Share.svg"))
    .set("Strikethrough", () => import("./assets/Strikethrough.svg"))
    .set("Thumbnail-View", () => import("./assets/Thumbnail-View.svg"))
    .set("Tiktok", () => import("./assets/Tiktok.svg"))
    .set("Token ID-Line", () => import("./assets/Token ID-Line.svg"))
    .set("Token ID", () => import("./assets/Token ID.svg"))
    .set("Token Type-Line", () => import("./assets/Token Type-Line.svg"))
    .set("Token Type", () => import("./assets/Token Type.svg"))
    .set("Trash Bin-Line", () => import("./assets/Trash Bin-Line.svg"))
    .set("Trash Bin", () => import("./assets/Trash Bin.svg"))
    .set("Twitter", () => import("./assets/Twitter.svg"))
    .set("UnLink", () => import("./assets/UnLink.svg"))
    .set("Undo", () => import("./assets/Undo.svg"))
    .set("Unlock-Fill", () => import("./assets/Unlock-Fill.svg"))
    .set("Unlock", () => import("./assets/Unlock.svg"))
    .set("Unpiin", () => import("./assets/Unpiin.svg"))
    .set("Up", () => import("./assets/Up.svg"))
    .set(
      "User Profile 1-Line",
      () => import("./assets/User Profile 1-Line.svg"),
    )
    .set("User Profile 1", () => import("./assets/User Profile 1.svg"))
    .set(
      "User Profile 2-Line",
      () => import("./assets/User Profile 2-Line.svg"),
    )
    .set(
      "User Profile 2-Settings",
      () => import("./assets/User Profile 2-Settings.svg"),
    )
    .set("User Profile 2", () => import("./assets/User Profile 2.svg"))
    .set("Video Playe-Line", () => import("./assets/Video Playe-Line.svg"))
    .set("Video Playe", () => import("./assets/Video Playe.svg"))
    .set("Write-Line", () => import("./assets/Write-Line.svg"))
    .set("Write", () => import("./assets/Write.svg"))
    .set("Youtube", () => import("./assets/Youtube.svg"))
    .set("_X", () => import("./assets/_X.svg"))
    .set("apple", () => import("./assets/apple.svg"))
    .set("google", () => import("./assets/google.svg"))
    .set("logo-Chrome", () => import("./assets/logo-Chrome.svg"))
    .set("logo-Github 2", () => import("./assets/logo-Github 2.svg"))
    .set("logo-Github", () => import("./assets/logo-Github.svg"))
    .set("logo-Googleplay", () => import("./assets/logo-Googleplay.svg"))
    .set("mention-Line", () => import("./assets/mention-Line.svg"))
    .set("mention", () => import("./assets/mention.svg"))
    .set("text-underline", () => import("./assets/text-underline.svg"));
