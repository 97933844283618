import React from "react";

import EditorUtilsProvider from "./editorUtils";
import UuidProvider from "./uuid";
import { ReactRouterProvider } from "./reactRouter";

function ContextProvider({ children }: { children: React.ReactNode }) {
  return (
    <ReactRouterProvider>
      <UuidProvider>
        <EditorUtilsProvider>{children}</EditorUtilsProvider>
      </UuidProvider>
    </ReactRouterProvider>
  );
}

export default ContextProvider;
