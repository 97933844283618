import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  menuController,
  pageController,
  pathController,
  windowController,
} from "~/store/space/reducers";
import { RootState } from "..";
import { SpaceState } from "./types";

const initialState: SpaceState = {
  isOpen: false,
  selectedMenu: "Profile",
  selectedMember: null,
  navigationPath: {
    // 스페이스 경로의 초기 값 배열은 메뉴 이름과 동일합니다. 경로는 메뉴 별로 독립적으로 관리됩니다.
    Profile: ["Profile"],
    "Digital collectibles": ["Digital collectibles"],
    "My Account": ["My Account"],
    "Creator earning": ["Creator earning"],
    "Market history": ["Market history"],
    Cryptocurrency: ["Cryptocurrency"],
    "My Payments": ["My Payments"],
  },
  currentViewPage: {
    Profile: "ProfileInfo",
    "Digital collectibles": "ContractList",
    Cryptocurrency: "Currencies",
    "Creator earning": "FeeSetting",
    "Market history": "MarketHistory",
    "My Account": "AccountSetting",
    "My Payments": "PaymentsSetting",
  },
};

export const spaceSlice = createSlice({
  name: "space",
  initialState,
  reducers: {
    // 스페이스 모달창 열기, 닫기
    open: windowController.open,
    close: windowController.close,

    // 메뉴 선택
    updateMenuSelect: menuController.select,

    // 경로 제어
    resetMenuPathPageAll: pathController.resetMenuPathPageAll,
    pushPath: pathController.pushPath,
    popPath: pathController.popPath,

    // 뷰 제어
    switchPageTo: pageController.switchPage,

    setSelectedMember: (
      state,
      action: PayloadAction<SpaceState["selectedMember"]>,
    ) => {
      state.selectedMember = action.payload;
    },
  },
});

export const { setSelectedMember } = spaceSlice.actions;

export const selectSelectedMember = (state: RootState) =>
  state.space.selectedMember;
